import React, { useEffect, useState, useRef } from "react";
import { Table, Button, Input } from "antd";
import moment from "moment";
import TrailModel from "./TrailModel";

import { useReactToPrint } from "react-to-print";
import {
  getRequestByCustomerWiseAPi,
  deleteMultipleRequest,
  exportMail,
} from "../../../Services/adminServices/service";
import { useToasts } from "react-toast-notifications";
import UpdateModal from "./UpdateModal";

export default function CustomerWise() {
  const { addToast } = useToasts();

  const [tableData, setTableData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTrailModelOpen, setIsTrailModelOpen] = useState(false)
  const [showTrailData, setshowTrailData] = useState({})
  const [tableDataIsLoading, setTableDataIsLoading] = useState(true);
  const [reloadApi, setReloadApi] = useState(true);

  const [isDeleteBtnDisabled, setIsDeleteBtnDisabled] = useState(false);
  const [isExportbtnDisabled, setIsExportbtnDisabled] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRecordId, setSelectedRecordId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");
  const [customerId, setCustomerId] = useState("");

  const allRequestPrint = useRef();
  const print = useReactToPrint({
    content: () => allRequestPrint.current,
  });
  const columns = [
    {
      title: "Customer id",
      dataIndex: ["userId", "customerId"],
      key: "name",
    },
    {
      title: "Customer Name",
      dataIndex: ["userId", "name"],
      key: "name",
    },
    {
      title: "Contact number",
      dataIndex: ["userId", "mobile"],
      key: "name",
    },
    {
      title: "Request Number",
      dataIndex: "req_no",
      key: "createdAt",
    },
    {
      title: "Request Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => (
        <>{moment(record.createdAt).format("DD/MM/YYYY")}</>
      ),
    },
    {
      title: "Service Category",
      dataIndex: ["serviceCategoryId", "name"],
      key: "name",
    },
    {
      title: "Sub-Category",
      dataIndex: ["subCategoryId", "name"],
      key: "name",
    },
    // {
    //   title: 'Appointment time',
    //   dataIndex: 'name',
    //   key: 'name',
    // },

    {
      title: "Reason",
      dataIndex: "reason",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // sorter: (a, b) => a?.name?.length - b?.name?.length,
      render: (_, record) => (
        <>
          <b>
            {record.status == 1
              ? "Pending"
              : record.status == 2
              ? "In Progress"
              : record.status == 3
              ? "Ready To Close"
              : "Closed"}
          </b>
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="btnDiv">
          <Button onClick={() => showModel(record)}>Update Request</Button>
          <Button onClick={() => showTrailModel(record.notes)}>Track Request</Button>
        </div>
      ),
    },
  ];
  const showTrailModel=(value)=>{
    setshowTrailData(value)
    setIsTrailModelOpen(true)
  }

  const showModel = (record) => {
    setSelectedRecordId(record._id);
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const deleteMultipleRequestFun = () => {
    if (selectedRowKeys.length < 1) {
      addToast("Please Select Row", { appearance: "error" });
      return;
    }
    setIsDeleteBtnDisabled(true);
    deleteMultipleRequest(selectedRowKeys)
      .then((res) => {
        // console.log(res);
        setReloadApi(!reloadApi);
        addToast("successfully Deleted", { appearance: "success" });
      })
      .catch((e) => {
        addToast("Something went wrong", { appearance: "error" });
        console.log(e);
      });
    setIsDeleteBtnDisabled(false);
  };
  const exportAsMail = (value) => {
    setIsExportbtnDisabled(true);
    if (value?.length < 1) {
      addToast("No Data To Export", { appearance: "error" });
      return;
    }
    exportMail(value, 6)
      .then((res) => {
        // console.log(res);
        addToast("Email Sent Successfully", { appearance: "success" });
        setIsExportbtnDisabled(false);
      })
      .catch((e) => {
        console.log(e);
        setIsExportbtnDisabled(false);
        addToast(e?.msg ? e?.msg : "Something went wrong", {
          appearance: "error",
        });
      });
  };

  useEffect(() => {
    // console.log("reloadApi", reloadApi);
    getRequestByCustomerWiseAPi(customerName, customerMobile, customerId)
      .then((res) => {
        // console.log(res);
        setTableData(res.data?.data);
        setTableDataIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [customerName, customerMobile, customerId, reloadApi]);
  return (
    <div className="overflow-x">
      {/* <h3>Customer Reports</h3> */}
      <div className="row">
        <div className="col-md-4">
          <Input
            placeholder="Search By Customer Name"
            onChange={(e) => setCustomerName(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <Input
            placeholder="Search By Contact Number"
            onChange={(e) => setCustomerMobile(e.target.value)}
          />
        </div>
        <div className="col-md-4">
          <Input
            placeholder="Search By Contact Id"
            onChange={(e) => setCustomerId(e.target.value)}
          />
        </div>
      </div>
      <div ref={allRequestPrint} className="tableContainer">
        <div className="container ">
          <h5>All Service Request </h5>
          <Table
            rowSelection={rowSelection}
            rowKey="_id"
            loading={tableDataIsLoading}
            columns={columns}
            dataSource={tableData}
          />
        </div>
      </div>
      <div className="exportBtn">
        <Button
          danger
          disabled={isDeleteBtnDisabled}
          loading={isDeleteBtnDisabled}
          onClick={deleteMultipleRequestFun}
        >
          Delete
        </Button>
        <Button
          disabled={isExportbtnDisabled}
          loading={isExportbtnDisabled}
          onClick={() => exportAsMail(tableData)}
        >
          Export as Mail
        </Button>
        <Button onClick={print}>Export As Pdf</Button>
      </div>
      {isModalOpen && selectedRecordId && (
        <UpdateModal
          handleCancel={handleCancel}
          visible={isModalOpen}
          reloadApi={reloadApi}
          setReloadApi={setReloadApi}
          selectedRecordId={selectedRecordId}
        />
      )}
      { isTrailModelOpen&&(
            <TrailModel
            visible={isTrailModelOpen}
            data={showTrailData}
            handleCancel={()=>setIsTrailModelOpen(false)}
            />

          )
        }
    </div>
  );
}
import React from "react";
import { LoginOutlined } from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
export default function AdminHeader() {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <div className="adminHeader">
      <img src="/assets/images/fintax1.jpeg" className="dashBoard_img" />
      
      <span className="logoutBtn" onClick={logout} title="Logout">


        <LoginOutlined />
      </span>
    </div>
  );
}

import React, { useState } from 'react'
import { Button, Modal, Form, Input, Select } from "antd";
export default function CreateUserModal({ visible,
    handleCancel, submit }) {
    const [inputData, setInputData] = useState({
        name: '',
        email: '',
        password: '',
        country: '',
        mobile: '',
        businessName: '',
        type: '',
        gstNumber: '',
        referalCode: '',
    })
const [isCreateBtnDisable, setIsCreateBtnDisable] = useState(true)
    const handleChange = (key, value) => {
        // console.log(key, value)
        let tmp = { ...inputData }
        tmp[key] = value
        setInputData({ ...tmp })
    }
    const handleSubmit=()=>{
        
    }
    return (
        <Modal
            title="Create Customer"
            open={visible}
            onCancel={handleCancel}
            footer={null}
        >
            {/* <div className="form_input"> */}

            <label>Name<span className="require">*</span></label>
            <input type="text" className="form-control" value={inputData.name} onChange={(e) => handleChange('name', e.target.value)} />
            <label>Password<span className="require">*</span></label>
            <input type="password" className="form-control" value={inputData.password} onChange={(e) => handleChange('password', e.target.value)} />
            <label>Email<span className="require">*</span></label>
            <input type="email" className="form-control" value={inputData.email} onChange={(e) => handleChange('email', e.target.value)} />
            <label>Mobile<span className="require">*</span></label>
            <input type="number" className="form-control" value={inputData.mobile} onChange={(e) => handleChange('mobile', e.target.value)} />
            <label>Business Name<span className="require"></span></label>
            <input type="text" className="form-control" value={inputData.businessName} onChange={(e) => handleChange('businessName', e.target.value)} />
            <Button type="primary" loading={isCreateBtnDisable}
            style={{
                marginTop:20
            }}
            >Create</Button>
            {/* </div> */}

        </Modal>
    )
}

import React from "react";
import Accordion from "react-bootstrap/Accordion";

export default function ServiceTemplate() {
  const filingObj = {
    title: "Filing",
    categories: [
      { id: 1, value: "Income Tax And Return" },
      { id: 2, value: "GST" },
      { id: 3, value: "Company affair" },
      { id: 4, value: "Import/Export" },
    ],
  };
  const planningObj = {
    title: "Planning",
    categories: [
      {
        id: 1,
        value: `Advance of
    Tax through legal planning`,
      },
      {
        id: 2,
        value: `Creation of
    entities`,
      },
      { id: 3, value: `Trust` },
      { id: 4, value: `Cheritable` },
      { id: 5, value: `Beneficiary` },
      {
        id: 6,
        value: `Application of
    available deductions/exemptions/available per law.`,
      },
      {
        id: 7,
        value: `Value
    creation in business assets,Agri and Dairy.`,
      },
    ],
  };
  const consultancyObj = {
    title: "Consultancy",
    categories: [
      {
        id: 1,
        value: `Income Tax
    Cases`,
      },
      {
        id: 2,
        value: `Income tax Raid
    Cases`,
      },
      {
        id: 3,
        value: `Company Law
    Affair`,
      },
      {
        id: 4,
        value: `GST
    Cases`,
      },
      {
        id: 5,
        value: `Refund of
    income Tax and GST`,
      },
    ],
  };
  const advisoryObj = {
    title:"Advisory",
    categories: [{id:1,value:`Development of
    Management system and Audit.`},{id:2,value:`Development of
    Accounting system and Evaluation.`},{id:3,value:`To select/advice
    Agri-business-Government Subsidy/Grant available.`}]
  };
  const financeObj = {
    title:"Finance",
    categories:[{id:1,value:`Long
    Term/Short Term/Personal Loan.`},{id:2,value:`Finance
    against securities and immovable assets.`},{id:3,value:`Commodity
    Finance for your needs Finance against securities and
    immovable assets.`},{id:4,value:`Loans for
    Agricultural Business/Dairy and support in getting
    Subsidy/Grant from Government.`}]
  };
  const investmentObj = {
    title:"Investment",
    categories:[{id:1,value:`Join
    Ventures`},{id:2,value:`Mutual
    Funds`}]
  }
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-5 mb-lg-0 text-center">
              <h2 className="heading-text">
                Find the best services and consulting for affordable deals.
              </h2>
              <p>
                Become a member and avail our premium services in affordable
                price. We have designed our membership plans which are the best
                fit to your Finance, Taxation and Investments services.
              </p>
            </div>
            <div className="col-lg-6 mb-5 mb-lg-0 text-center">
              <img src="./assets/img/services/business-1.jpg" alt="#"></img>
            </div>
          </div>
        </div>
      </section>
      <section className="taxation bg-light" id="ourservices">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-8 text-center w-md-50 mx-auto mb-0">
              <h2 className="mb-md-2">Our Services</h2>
              <p className="lead text-muted">Explore More</p>
              <h1 className="taxation">Taxation & Corporate Consultancy</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="taxation bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{filingObj.title}</Accordion.Header>
                  <Accordion.Body>
                    {filingObj.categories.map((item) => (
                      <div className="list-group-item">
                        <i class="zmdi zmdi-star zmdi-hc-fw"></i>
                        {item.value}
                      </div>
                    ))}

                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-md-4">
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{planningObj.title}</Accordion.Header>
                  <Accordion.Body>
                    {planningObj.categories.map((item) => (
                      <div className="list-group-item">
                        <i class="zmdi zmdi-star zmdi-hc-fw"></i>
                        {item.value}
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-md-4">
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{consultancyObj.title}</Accordion.Header>
                  <Accordion.Body>

                  {consultancyObj.categories.map((item) => (
                      <div className="list-group-item">
                        <i class="zmdi zmdi-star zmdi-hc-fw"></i>
                        {item.value}
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <section className="taxation bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{advisoryObj.title}</Accordion.Header>
                  <Accordion.Body>
                  {advisoryObj.categories.map((item) => (
                      <div className="list-group-item">
                        <i class="zmdi zmdi-star zmdi-hc-fw"></i>
                        {item.value}
                      </div>
                    ))}
                   
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-md-4">
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{financeObj.title}</Accordion.Header>
                  <Accordion.Body>
                  {financeObj.categories.map((item) => (
                      <div className="list-group-item">
                        <i class="zmdi zmdi-star zmdi-hc-fw"></i>
                        {item.value}
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-md-4">
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{investmentObj.title}</Accordion.Header>
                  <Accordion.Body>
                  {investmentObj.categories.map((item) => (
                      <div className="list-group-item">
                        <i class="zmdi zmdi-star zmdi-hc-fw"></i>
                        {item.value}
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

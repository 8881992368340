import React, { useState, useEffect } from "react";
import { submitContactForm } from "../Services/userServices/service";
import { useToasts } from "react-toast-notifications";
import {
  getSubCategoryByCategoryId,
  getAllCategory,
} from "../Services/adminServices/service";
import { Select, Input } from "antd";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function ContactTemplate() {
  const { addToast } = useToasts();
  const [inputData, setInputData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    remark: "",
    servicecategory: "",
    subcategory: "",
    other: "",
  });
  const [subCategoryFilterValues, setsubCategoryFilterValues] = useState([]);
  const [categoryFilterValues, setCategoryFilterValues] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Select a category");
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    "Select a sub-category"
  );
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (key, value) => {
   
    if (key === "servicecategory" && value === "other") {
      setShowOtherInput(true);
      return;
    } 
     if(key === "servicecategory" && value !== "other"){
      setShowOtherInput(false);
    }
     
      let tmp = { ...inputData };
      tmp[key] = value;
      setInputData({ ...tmp });
    
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(inputData);
    submitContactForm(inputData)
      .then((res) => {
        addToast("successfully Submitted", { appearance: "success" });
        handleShow();
        let tmp = {
          firstname: "",
          lastname: "",
          email: "",
          remark: "",
          serviceCategory: "",
          subcategory: "",
          other: "",
        };
        setInputData({ ...tmp });
        setSelectedCategory("Select a category");
        setSelectedSubCategory("Select a sub-category");
      })
      .catch((e) => {
        if (e.response?.status == 400) {
          addToast("All fields are mandatory", { appearance: "error" });
        } else {
          addToast("Something went wrong", { appearance: "error" });
        }
      });
  };
  useEffect(() => {
    getAllCategory()
      .then((res) => {
        let tmp = res?.data?.data;
        // console.log(tmp);
        let arr = [];
        if (tmp) {
          for (let x of tmp) {
            // console.log("x", x);
            arr.push({
              label: x.name,
              value: x._id,
            });
          }
          arr.push({
            label: "Other",
            value: "other",
          });
          // console.log("arr", arr);
          setCategoryFilterValues([...arr]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (selectedCategory === "other" || selectedCategory === "Select a category") {
      setsubCategoryFilterValues([]);
    } else {
      getSubCategoryByCategoryId(selectedCategory)
        .then((res) => {
          let tmp = res?.data?.data;
          let arr = [];

          if (tmp) {
            for (let x of tmp) {
              arr.push({
                label: x.name,
                value: x._id,
              });
            }
            // console.log(arr);
            setsubCategoryFilterValues([...arr]);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedCategory]);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <p className="contact-txt text-center">
              Rather than just filling out a form, taxAdvice also offers help to
              the user with links directing them to find additional information
              or take popular actions. They’ve also taken special care to add
              social proof, featuring trusted logos.
            </p>
          </div>
        </div>
        <div className="contact__wrapper shadow-lg mt-n9">
          <div className="row no-gutters">
            <div className="col-md-6 contact-form__wrapper p-5 order-lg-1">
              <p>Please fill the details for enquiry and details.</p>

              <form
                action="#"
                className="contact-form form-validate"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    <div className="form-group">
                      <label className="required-field" for="firstName">
                        First Name <span className="require">*</span>
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="firstName"
                        value={inputData.firstname}
                        onChange={(e) => {
                          handleChange("firstname", e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 mb-3">
                    <div className="form-group">
                      <label className="required-field" htmlFor="lastName">
                        Last Name <span className="require">*</span>
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lastName"
                        value={inputData.lastname}
                        onChange={(e) => {
                          handleChange("lastname", e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 mb-3">
                    <div className="form-group">
                      <label className="required-field" for="email">
                        Email <span className="require">*</span>
                      </label>
                      <Input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={inputData.email}
                        onChange={(e) => {
                          handleChange("email", e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 mb-3">
                    {categoryFilterValues &&
                      categoryFilterValues.length > 0 && (
                        <>
                          <label
                            className="required-field"
                            for="categoryDropdown"
                          >
                            Category <span className="require">*</span>
                          </label>
                          <Select
                            // class="form-control"

                            id="categoryDropdown"
                            size="large"
                            onChange={(value) => {
                              // console.log(value);
                              handleChange("servicecategory", value);
                              setSelectedCategory(value);
                            }}
                            value={selectedCategory}
                            options={categoryFilterValues}
                            placeholder="Select a category"
                          ></Select>
                        </>
                      )}
                  </div>
                  {selectedCategory &&
                    subCategoryFilterValues &&
                    subCategoryFilterValues.length > 0 && (
                      <div className="col-sm-6 mb-3">
                        <label
                          className="required-field"
                          for="categoryDropdown"
                        >
                          Subcategory <span className="require">*</span>
                        </label>
                        <Select
                          // class="form-control"

                          id="categoryDropdown"
                          size="large"
                          onChange={(value) => {
                            // console.log(value);
                            handleChange("subcategory", value);
                            setSelectedSubCategory(value);
                          }}
                          value={selectedSubCategory}
                          options={subCategoryFilterValues}
                          placeholder="Select a sub-category"
                        ></Select>
                      </div>
                    )}
                  {showOtherInput && (
                    <div className="col-sm-6 mb-3">
                      <label className="required-field" for="message">
                        Other <span className="require">*</span>
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        value={inputData.other}
                        onChange={(e) => {
                          handleChange("other", e.target.value);
                        }}
                      />
                    </div>
                  )}

                  <div class="col-sm-12 mb-3">
                    <div class="form-group">
                      <label className="required-field" for="message">
                        Remark <span className="require">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        value={inputData.remark}
                        rows="4"
                        onChange={(e) => {
                          handleChange("remark", e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <span className="mandatory">
                    * Fields are mandatory in red color.{" "}
                  </span>

                  <div class="col-sm-12 mb-3">
                    <button
                      type="submit"
                      name="submit"
                      className="btn btn-primary submit_btn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title> Thank You</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              Thank you for contacting us. 
              We'll get you back to you shortly.
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <div className="col-md-6 contact-info__wrapper gradient-brand-color p-5 order-lg-2">
              <h3 className="color--white mb-5">Get in Touch</h3>

              <ul className="contact-info__list list-style--none position-relative z-index-101">
                <li className="mb-4 pl-4">
                  <span className="position-absolute">
                    <i className="fas fa-envelope"></i>
                  </span>{" "}
                  mail@fintaxcial.com
                </li>
                <li class="mb-4 pl-4">
                  <span className="position-absolute">
                    <i className="fas fa-phone"></i>
                  </span>{" "}
                  +91-7204443000
                </li>
                <li class="mb-4 pl-4">
                  <span className="position-absolute">
                    <i className="fas fa-map-marker-alt"></i>
                  </span>{" "}
                  #402, Bijith Tower
                  <br /> Babusapaliya, Bangalore, KA 560043 IN
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import { baseUrl } from "../../Config/config";

export default function Subscription() {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [plan, setplan] = useState("");

  const subscribe = () => {
    if (!plan) {
      addToast("Please select a plan", { appearance: "error" });
      return;
    }
    axios
      .post(baseUrl + "payment/payumoney",{
        amount: plan == "Individual" ? 1000 : 5000,
        plan:plan
      })
      .then((res) => {
       window.open(res.data)
       window.close()
      })
      .catch((e) => {
        addToast("Something went wrong try again", { appearance: "error" });
        console.log(e);
      });
  };



  

  return (
    <div className="container">
      <section>
        <div className="header-section">
          <h1>Our Best Offers (Please Select a Plan) </h1>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="col-lg-6 mb-5 mb-lg-0 text-center">
            <div
              className={
                plan == "Individual"
                  ? "membershipCard selectediv"
                  : "membershipCard"
              }
              onClick={() => setplan("Individual")}
            >
              <h4>Individual Plan</h4>
              <p>₹1000/Year</p>
            </div>
          </div>

          <div className="col-lg-6 mb-5 mb-lg-0 text-center">
            <div
              className={
                plan == "corporate"
                  ? "membershipCard selectediv"
                  : "membershipCard"
              }
              onClick={() => setplan("corporate")}
            >
              <h4>Corporate Plan</h4>
              <p>₹5000/Year</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="container">
            <div className="col-lg-12 mb-5 mb-lg-0 text-center">
              <Button type="primary" onClick={subscribe}>
                Upgrade
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

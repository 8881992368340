import React, { useState, useEffect, useRef } from "react";
// import Table from '../Component/Table'
import { Table, Button, Modal, Form, Select, Input } from "antd";
import moment from "moment";
import download from 'downloadjs';
import { useReactToPrint } from "react-to-print";
import { useToasts } from "react-toast-notifications";
import fileDownload from 'js-file-download';

import {
  exportMail,
  getRequestByStatusApi,
  getAllSubCategory,
  getAllCategory,
  deleteMultipleRequest,
  genratePdf
} from "../../../Services/adminServices/service";
import UpdateModal from "./UpdateModal";
import TrailModel from "./TrailModel";

export default function ServiceWise() {
  const { addToast } = useToasts();
  const [isModalOpen, setIsModelOpen] = useState(false);
  const [isTrailModelOpen, setIsTrailModelOpen] = useState(false)
  const [showTrailData, setshowTrailData] = useState({})
  const [newRequest, setNewRequest] = useState([]);
  const [inProgressReports, setInProgressReports] = useState([]);
  const [readyToCloseReports, setReadyToCloseReports] = useState([]);
  const [closedReports, setClosedReports] = useState([]);
  const [reloadApi, setReloadApi] = useState(true);
  const [subCategoryFilterValues, setsubCategoryFilterValues] = useState([]);
  const [categoryFilterValues, setCategoryFilterValues] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isDeleteBtnDisabled, setIsDeleteBtnDisabled] = useState(false);
  const [isExportbtnDisabled, setIsExportbtnDisabled] = useState(false);
  const [ispdfBtnDisabled, setispdfBtnDisabled] = useState(false)
  const [selectedRecordId, setSelectedRecordId] = useState("");

  const newRequestRef = useRef();
  const inProgressReportsRef = useRef();
  const readyToCloseReportsRef = useRef();
  const closedReportsRef = useRef();
  const printNewRequestReports = useReactToPrint({
    content: () => newRequestRef.current,
  });
  const printInProgressReports = useReactToPrint({
    content: () => inProgressReportsRef.current,
  });
  const printReadyToCloseReports = useReactToPrint({
    content: () => readyToCloseReportsRef.current,
  });
  const printClosedReports = useReactToPrint({
    content: () => closedReportsRef.current,
  });
  const columns = [
    {
      title: "Request Number",
      dataIndex: "req_no",
      key: "req_no",
    },
    {
      title: "Request Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => (
        <>{moment(record.createdAt).format("DD/MM/YYYY")}</>
      ),
    },
    {
      title: "Customer id",
      dataIndex: ["userId", "customerId"],
      key: "name",
    },
    {
      title: "Customer Name",
      dataIndex: ["userId", "name"],
      key: "name",
    },
    {
      title: "Contact number",
      dataIndex: ["userId", "mobile"],
      key: "name",
    },
    {
      title: "Service Category",
      dataIndex: ["serviceCategoryId", "name"],
      key: "name",
      // filters: categoryFilterValues,
      // filterMode: 'tree',
      // filterSearch: true,
      onFilter: (value, record) =>
        record?.serviceCategoryId?.name?.startsWith(value),
    },
    {
      title: "Sub-Category",
      dataIndex: ["subCategoryId", "name"],
      key: "name",
      // filters: subCategoryFilterValues,
      // filterMode: 'tree',
      // filterSearch: true,
      onFilter: (value, record) =>
        record?.subCategoryId?.name.startsWith(value),
    },
    // {
    //   title: 'Appointment time',
    //   dataIndex: 'name',
    //   key: 'name',
    // },

    {
      title: "Reason",
      dataIndex: "reason",
      key: "name",
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   // sorter: (a, b) => a?.name?.length - b?.name?.length,
    //   render: (_, record) => (
    //     <><b>{record.status == 1 ? "Pending" : record.status == 2 ? "In Progress" : record.status == 3 ? "Ready To Close" : "Closed"}</b></>
    //   ),
    // },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="btnDiv">
          <Button onClick={() => showModel(record)}>Update Request</Button>
          <Button onClick={() => showTrailModel(record.notes)}>Track Request</Button>
        </div>
      ),
    },
  ];
  const showTrailModel=(value)=>{
    setshowTrailData(value)
    setIsTrailModelOpen(true)
  }

  const handleCancel = () => {
    setIsModelOpen(false);
  };
  const showModel = (record) => {
    setSelectedRecordId(record._id);
    setIsModelOpen(true);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const deleteMultipleRequestFun = () => {
    if (selectedRowKeys.length < 1) {
      addToast("Please Select Row", { appearance: "error" });
      return;
    }
    setIsDeleteBtnDisabled(true);
    deleteMultipleRequest(selectedRowKeys)
      .then((res) => {
        // console.log(res);
        setReloadApi(!reloadApi);
        addToast("successfully Deleted", { appearance: "success" });
      })
      .catch((e) => {
        addToast("Something went wrong", { appearance: "error" });
        console.log(e);
      });
    setIsDeleteBtnDisabled(false);
  };

  const exportAsMail = (value, status) => {
    setIsExportbtnDisabled(true);
    if (value?.length < 1) {
      addToast("No Data To Export", { appearance: "error" });
      return;
    }
    exportMail(value, status)
      .then((res) => {
        // console.log(res);
        addToast("Email Sent Successfully", { appearance: "success" });
        setIsExportbtnDisabled(false);
      })
      .catch((e) => {
        setIsExportbtnDisabled(false);
        console.log(e);
        addToast(e?.msg ? e?.msg : "Something went wrong", {
          appearance: "error",
        });
      });
  };

  const genratepdfFun=(payload,status)=>{
    setispdfBtnDisabled(true)
    if (payload?.length < 1) {
      addToast("No Data To Export", { appearance: "error" });
      return;
    }
    genratePdf(payload,status)
    .then(async(response)=>{
      fileDownload(response.data,"download.pdf")
      setispdfBtnDisabled(false)
    })
    .catch((e)=>{
      console.log(e)
      addToast(e?.msg ? e?.msg : "Something went wrong", {
        appearance: "error",
      });
      setispdfBtnDisabled(false)
    })
  }

  useEffect(() => {
    getAllSubCategory()
      .then((res) => {
        let tmp = res?.data?.data;
        let arr = [];

        if (tmp) {
          for (let x of tmp) {
            arr.push({
              text: x.name,
              value: x._id,
            });
          }
          // console.log(arr);
          setsubCategoryFilterValues([...arr]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    getAllCategory()
      .then((res) => {
        let tmp = res?.data?.data;
        // console.log(tmp);
        let arr = [];
        if (tmp) {
          for (let x of tmp) {
            // console.log("x", x);
            arr.push({
              text: x.name,
              value: x._id,
            });
          }
          // console.log("arr", arr);
          setCategoryFilterValues([...arr]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    // console.log(selectedCategory, selectedSubCategory);
    setIsTableLoading(true);

    getRequestByStatusApi({
      status: 1,
      category: selectedCategory,
      subcategory: selectedSubCategory,
    })
      .then(function (response) {
        // console.log(response.data);
        let tmp = response.data.data;
        setNewRequest([...tmp]);
      })
      .catch(function (error) {
        console.log(error);
      });

    getRequestByStatusApi({
      status: 2,
      category: selectedCategory,
      subcategory: selectedSubCategory,
    })
      .then(function (response) {
        // console.log(response.data);
        let tmp = response.data.data;
        setInProgressReports([...tmp]);
      })
      .catch(function (error) {
        console.log(error);
      });

    getRequestByStatusApi({
      status: 3,
      category: selectedCategory,
      subcategory: selectedSubCategory,
    })
      .then(function (response) {
        // console.log(response.data);
        let tmp = response.data.data;
        setReadyToCloseReports([...tmp]);
      })
      .catch(function (error) {
        console.log(error);
      });

    getRequestByStatusApi({
      status: 4,
      category: selectedCategory,
      subcategory: selectedSubCategory,
    })
      .then(function (response) {
        // console.log(response.data);
        let tmp = response.data.data;
        setClosedReports([...tmp]);
      })
      .catch(function (error) {
        console.log(error);
      });
    setIsTableLoading(false);
  }, [reloadApi, selectedCategory, selectedSubCategory]);

  return (
    <div className="admin_controller">
      {/* <h3>Customer Reports</h3> */}

      <div className="dashboard_section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              {categoryFilterValues && categoryFilterValues.length && (
                <select
                  class="form-control admin"
                  id="exampleFormControlSelect2"
                  onChange={(e) => {
                    // console.log(e.target.value);
                    setSelectedCategory(e.target.value);
                  }}
                >
                  {categoryFilterValues?.length > 0 ? (
                    <>
                      <option disabled selected>
                        --Select Service Category--
                      </option>
                      {categoryFilterValues?.map((item, index) => {
                        return (
                          <>
                            <option value={item.value}>{item.text} </option>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <option disabled>No Service Category</option>
                    </>
                  )}
                </select>
              )}
            </div>
            <div className="col-md-6">
              {subCategoryFilterValues && subCategoryFilterValues.length && (
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  required
                  onChange={(e) => {
                    // console.log(e.target.value)
                    // let tmp = { ...inputData }
                    // tmp.subCategoryId = e.target.value
                    setSelectedSubCategory(e.target.value);
                    // setInputData({ ...tmp })
                  }}
                >
                  {subCategoryFilterValues?.length > 0 ? (
                    <>
                      <option disabled selected>
                        --Select Service Sub-Category--
                      </option>

                      {subCategoryFilterValues?.map((item, index) => {
                        return (
                          <>
                            <option value={item.value}>{item.text} </option>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <option disabled>No Service Sub-Category</option>
                    </>
                  )}
                </select>
              )}
            </div>
          </div>
          <div className="tableContainer">
            <div ref={newRequestRef}>
              <h5 className="service_txt">New Service Request</h5>

              <Table
                rowSelection={rowSelection}
                rowKey="_id"
                loading={isTableLoading}
                columns={columns}
                dataSource={newRequest}
              />
            </div>
          </div>
          <div className="exportBtn">
            <Button
              danger
              disabled={isDeleteBtnDisabled}
              loading={isDeleteBtnDisabled}
              onClick={deleteMultipleRequestFun}
            >
              Delete
            </Button>
            <Button
              disabled={isExportbtnDisabled}
              loading={isExportbtnDisabled}
              onClick={() => {
                exportAsMail(newRequest, 1);
              }}
            >
              Export as Mail
            </Button>
            <Button onClick={()=>genratepdfFun(newRequest,1)}>Export As Pdf</Button>
          </div>
        </div>
        <div className="container tableContainer">
          <div ref={inProgressReportsRef}>
            <h5>In Progress Service Requests</h5>
            <Table
              rowSelection={rowSelection}
              rowKey="_id"
              loading={isTableLoading}
              columns={columns}
              dataSource={inProgressReports}
            />
          </div>
          <div className="exportBtn">
            <Button
              danger
              disabled={isDeleteBtnDisabled}
              loading={isDeleteBtnDisabled}
              onClick={deleteMultipleRequestFun}
            >
              Delete
            </Button>
            <Button
              disabled={isExportbtnDisabled}
              loading={isExportbtnDisabled}
              onClick={() => exportAsMail(inProgressReports, 2)}
            >
              Export as Mail
            </Button>
            <Button onClick={()=>genratepdfFun(inProgressReports,2)}>Export As Pdf</Button>
          </div>
        </div>
        <div className="container tableContainer">
          <div ref={readyToCloseReportsRef}>
            <h5>Ready To Close Service Requests</h5>

            <Table
              rowSelection={rowSelection}
              rowKey="_id"
              loading={isTableLoading}
              columns={columns}
              dataSource={readyToCloseReports}
            />
          </div>
          <div className="exportBtn">
            <Button
              danger
              disabled={isDeleteBtnDisabled}
              loading={isDeleteBtnDisabled}
              onClick={deleteMultipleRequestFun}
            >
              Delete
            </Button>
            <Button
              disabled={isExportbtnDisabled}
              loading={isExportbtnDisabled}
              onClick={() => exportAsMail(readyToCloseReports, 3)}
            >
              Export as Mail
            </Button>
            <Button onClick={()=>genratepdfFun(readyToCloseReports,3)}>Export As Pdf</Button>
          </div>
        </div>
        <div className="container tableContainer">
          <div ref={closedReportsRef}>
            <h5>Close Service Requests</h5>
            <Table
              rowSelection={rowSelection}
              rowKey="_id"
              loading={isTableLoading}
              columns={columns}
              dataSource={closedReports}
            />
          </div>
          <div className="exportBtn">
            <Button
              danger
              disabled={isDeleteBtnDisabled}
              loading={isDeleteBtnDisabled}
              onClick={deleteMultipleRequestFun}
            >
              Delete
            </Button>
            <Button
              disabled={isExportbtnDisabled}
              loading={isExportbtnDisabled}
              onClick={() => exportAsMail(closedReports, 4)}
            >
              Export as Mail
            </Button>
            <Button onClick={()=>genratepdfFun(closedReports,4)}>Export As Pdf</Button>
          </div>
        </div>
        {isModalOpen && selectedRecordId && (
          <UpdateModal
            handleCancel={handleCancel}
            visible={isModalOpen}
            reloadApi={reloadApi}
            setReloadApi={setReloadApi}
            selectedRecordId={selectedRecordId}
          />
        )}
        { isTrailModelOpen&&(
            <TrailModel
            visible={isTrailModelOpen}
            data={showTrailData}
            handleCancel={()=>setIsTrailModelOpen(false)}
            />

          )
        }
      </div>
    </div>
  );
}
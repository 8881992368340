import React, { useEffect, useState } from 'react'
import { Table, Button, Modal } from 'antd'
import { deleteUserApi, getAllUser, getContact } from '../../../Services/adminServices/service'
import { useToasts } from "react-toast-notifications";

export default function Contact() {
    const { addToast } = useToasts();


    const columns = [
        {
          title: 'First Name',
          dataIndex: 'firstname',
          key: 'firstname',
          // render: (text) => <a>{text}</a>,
        },
        {
          title: 'Last Name',
          dataIndex: 'lastname',
          key: 'lastname',
        },
        
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Service Category',
          dataIndex: ['servicecategory','name'],
          key: ['servicecategory','name'],
        },
        {
          title: 'Sub Category',
          dataIndex: ['subcategory','name'],
          key: ['subcategory','name'],
        },

        {
          title: 'Remark',
          dataIndex: 'remark',
          key: 'remark',
        },
        
     
        ]
    const [contactData, setContactData] = useState([])
    useEffect(() => {
        getContact()
            .then((res) => {
                setContactData(res?.data?.data)

            })
            .catch((e) => {
                console.log(e)
                addToast("Something went wrong", { appearance: "error" })
            })
    }, [])
    return (
        <div className='main-Page'>
            <Table
                columns={columns}
                dataSource={contactData}
            />

        </div>
    )
}

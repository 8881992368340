import React, { useState, useEffect } from 'react'
import Switch from "react-switch";
import { useNavigate } from 'react-router-dom';
import { getUserProfile, getAllTransaction, updateProfile } from '../../../Services/userServices/service';
import { Button } from 'antd';
import moment from 'moment';
import { useToasts } from "react-toast-notifications";


export default function Profile() {
    const { addToast } = useToasts();

    const navigate = useNavigate()
    const [userData, setUserData] = useState({
        name: "",
        mobile: "",
        businessName: '',
        gstNumber: ''
    })

    const [billingDetails, setBillingDetails] = useState([])
    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(userData)
        updateProfile(userData)
            .then(function (response) {
                // console.log(JSON.stringify(response.data));
                addToast("successfully updated", { appearance: "success" })
            })
            .catch(function (error) {
                if (error?.response?.status == 400) {
                    addToast(error?.response?.data, { appearance: "error" })
                } else {
                    addToast("Something went wrong", { appearance: "error" })
                }
                console.log(error);
            });



    }

    const handleChange = (key, value) => {
        let tmp = { ...userData }
        tmp[key] = value
        setUserData({ ...tmp })

    }
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('fintaxcialUser'))
        if (!userData?.token || !userData.role == 'user') {
            navigate("/login");
        }
        getUserProfile()
            .then(function (response) {
                // console.log(response.data?.data);
                setUserData(response.data?.data)


            })
            .catch(function (error) {
                console.log(error);
                addToast("Something went wrong", { appearance: "error" })

            });

        getAllTransaction()
            .then(function (response) {
                // console.log(response.data?.data);
                setBillingDetails(response.data?.data)


            })
            .catch(function (error) {
                console.log(error);
                addToast("Something went wrong", { appearance: "error" })

            });

    }, [])
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div class="col-md-8 ">
                    <h5 className="profile_txt">Profile Details:</h5>
                    <div class="row">

                        <div class="col-md-6">
                            <label for="billing_heading">Name</label>
                            <input type="text" value={userData.name} className='form-control'
                                onChange={(e) => {
                                    handleChange('name', e.target.value)
                                }}
                            />
                        </div>
                        <div class="col-md-6">
                            <label for="billing_heading">Phone Number</label>
                            <input type="number" value={userData.mobile} className='form-control'
                                onChange={(e) => {
                                    handleChange('mobile', e.target.value)
                                }}
                            />
                        </div>
                        <div className="col-md-6">
                            <label for="billing_heading">Country</label>
                            <select name="business" id="business" className="form-control"
                                onChange={(e) => handleChange("country", e.target.value)}
                            >
                                <option disabled selected value="Please Select Country">Please Select</option>
                                <option value="india">India</option>
                                <option value="Australia">Australia</option>
                                <option value="United States">United States</option>
                                <option value="United Kingdom">United Kingdom</option>
                            </select>

                        </div>
                        <div className="col-md-6">
                            <label for="billing_heading">Business Name</label>
                            <input type="Text" className='form-control' value={userData.businessName}
                                onChange={(e) => {
                                    handleChange('businessName', e.target.value)
                                }}
                            />

                        </div>
                        <div className="col-md-6">
                            <label for="billing_heading">GST Number</label>
                            <input type="Text" className='form-control' value={userData.gstNumber}
                                onChange={(e) => {
                                    handleChange('gstNumber', e.target.value)
                                }}
                            />

                        </div>

                        <div className="col-md-12">
                            <button className="btn btn-dark update_btn">Update</button>

                        </div>
                    </div>
                </div>
            </form>


            <section className="billing-text">
                <div className='conatiners'>

                    <div className="col-md-8 ">
                        <h5 className="billing_txt">Billing Details:</h5>
                        <div className="row">
                            {billingDetails.length > 0 ?
                                <>
                                    {billingDetails?.map((item, index) => {
                                        return <>

                                            <div className="col-md-6">
                                                <b>Billing Amount:</b><span> {item.transactionamount}</span>
                                            </div>
                                            <div className="col-md-6">
                                                <b>Billing Date:</b><span>   {moment(item.createdAt).format('DD/MM/YYYY')} </span>
                                            </div>
                                        </>

                                    })}
                                </> :
                                <>
                                    <div className="col-md-6 justify-content-center">
                                        No Billing Details
                                    </div>
                                </>
                            }



                        </div>
                    </div>


                </div>
            </section>

        </>
    )
}

import React, { useState } from 'react'
import { Button, Modal, Form, Input, Select } from "antd";
import Box from "@mui/material/Box";
// import { Stepper } from '@mui/material';
// import Stepper from '@mui/material/Stepper';
import { Stepper, Step, StepLabel, StepButton } from "@material-ui/core";
// import Step from "@mui/material/Step";
// import StepButton from "@mui/material/StepButton";
import moment from "moment";



export default function TrailModel({
    visible,
    handleCancel,
    data
}) {
    // console.log(data, "data11")
    const [activeStep, setactiveStep] = useState(0)


    const steps = [
        {
            Status: "progress",
            desc:
                "xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv ",
            date: "20/10/2000"
        },
        {
            Status: "progress",
            desc:
                "xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv ",
            date: "20/10/2000"
        },
        {
            status: "progress",
            desc:
                "xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv xshavcjhdv ",
            date: "20/10/2000"
        },

    ];
    const statusName=[
        '','New Request','In Progress Request',"Ready TO Close Request",'Closed Request'
    ]
    return (

        <Modal
            title="Track Request"
            open={visible}
            onCancel={handleCancel}
            footer={null}
        >
            <div className='request-progress'>
                {/* {data?.lengh>0? */}
                    <Box sx={{ width: "100%" }}>
                        <Stepper nonLinear orientation="vertical" activeStep={activeStep}>
                            {data?.length>0?
                            
                            data.map((label, index) => (
                                <Step key={index} completed={true}>
                                    <StepButton color="inherit"><h6>{label.status}</h6></StepButton>
                                    <br />
                                    <p>
                                        {" "}
                                        <b>Note:</b>
                                        {label.desc}
                                    </p>
                                    <p>
                                        {" "}
                                        <b>Date:</b>
                                        {/* {label.date} */}
                                        {moment(label?.date).format("DD/MM/YYYY hh:MM:ss")}
                                    </p>
                                    {/* </div> */}
                                </Step>
                            ))
                            :
                            <>
                            No Record Found
                            </>
                            }
                        </Stepper>
                    </Box>
                   {/* :
                    <>
                    No Record Found
                    </>
                }  */}

            </div>


        </Modal>
    )
}

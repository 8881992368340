import React from "react";
import { Button, Modal, Form, Input, Select } from "antd";

import { updateRequestStatusApi } from "../../../Services/adminServices/service";
const { TextArea } = Input;

export default function UpdateModal({
  visible,
  handleCancel,
  setReloadApi,
  reloadApi,
  selectedRecordId,
}) {
  // console.log(selectedRecordId);
  const [form] = Form.useForm();
  const onFinish = (value) => {
    updateRequestStatusApi(selectedRecordId, value.Status, value.note)
      .then(function (response) {
        setReloadApi(!reloadApi);
        handleCancel();
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Modal
      title="Update Request Status"
      open={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <Form initialValues={{ remember: true }} onFinish={onFinish} form={form}>
        <Form.Item name="id" label="Username" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name="Status"
          label="Status"
          rules={[{ required: true, message: "Please Select Status" }]}
        >
          <Select
            defaultValue="Select Status"
            style={{ width: "100%" }}
            options={[
              {
                label: "New Service Request",
                value: 1,
              },
              {
                label: "In Progress Service Request",
                value: 2,
              },
              {
                label: "Ready to Close Service Request",
                value: 3,
              },
              {
                label: "Closed Service Request",
                value: 4,
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="note"
          label="Note"
          rules={[{ required: false, message: "Please add a note" }]}
        >
          <TextArea
            rows={4}
            cols={50}
            className="form-control"
            placeholder="Add Note..."
          />
        </Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Update
        </Button>
      </Form>
    </Modal>
  );
}

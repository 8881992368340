import React, { useEffect, useState } from "react";
import {
  getUserDataApi,
  updateUserApi,
} from "../../../Services/adminServices/service";
import { useToasts } from "react-toast-notifications";

export default function Setting() {
  const { addToast } = useToasts();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [inputData, setInputData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleOnchange = (key, value) => {
    let tmp = { ...inputData };
    tmp[key] = value;
    setInputData({ ...tmp });
    // addToast("successfully Added", { appearance: "success" })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!inputData.name) {
      addToast("Please Enter Name", { appearance: "error" });
      return;
    }
    if (!inputData.email) {
      addToast("Please Enter Email", { appearance: "error" });
    }
    if (inputData?.password && inputData.password.length < 8) {
      addToast("password should be minimum 8 character", {
        appearance: "error",
      });
    }
    let tmp = {};
    if (inputData.name != name) {
      tmp.name = inputData.name;
    }
    if (inputData.email != email) {
      tmp.email = inputData.email;
    }
    if (inputData.password) {
      tmp.password = inputData.password;
    }
    updateUserApi(tmp)
      .then((res) => {
        addToast("Change successfully", { appearance: "success" });
      })
      .catch((e) => {
        console.log(e);
        addToast("Something went wrong", { appearance: "error" });
      });
  };

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem("fintaxcialUser"));
    if (userData?.token) {
      // console.log(userData);
      getUserDataApi()
        .then((res) => {
          // console.log(res);
          let data = res.data?.data;
          setName(data?.name);
          setEmail(data?.email);
          setInputData({
            name: data?.name,
            email: data?.email,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);
  return (
    <div className="admin-setting">
      <form onSubmit={handleSubmit}>
        <div class="col-md-8 ">
          <h5 className="profile_txt">Profile</h5>
          <div class="row">
            <div class="col-md-6">
              <label for="billing_heading">Name</label>
              <input
                type="text"
                value={inputData.name}
                className="form-control"
                placeholder="Enter Name"
                onChange={(e) => handleOnchange("name", e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div class="col-md-6">
              <label for="billing_heading">Email</label>
              <input
                type="email"
                value={inputData.email}
                className="form-control"
                placeholder="Enter Email"
                disabled
              />
              <span className="note">
                {" "}
                * Exported Data will be sent on this Email
              </span>
            </div>
          </div>

          {/* <div className="col-md-12"> */}
          <button type="submit" className="btn btn-dark update_btn">
            Update
          </button>

          {/* </div> */}
        </div>
      </form>
    </div>
  );
}

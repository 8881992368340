import React from 'react'
import LoginTemplate from '../../templates/admin/Login'
import Footer from '../../components/Footer'
export default function Login() {
  return (
    <div>
        <LoginTemplate/>
        <Footer/>
    </div>
  )
}

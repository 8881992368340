import { Button } from 'antd';
import Item from 'antd/es/list/Item';
import React, { useEffect, useState } from 'react'
import { useToasts } from "react-toast-notifications";
import { useNavigate } from 'react-router-dom';

import { getAllCategory, createRequestAPi, getAllSubCategory, getAllUser } from '../../../Services/adminServices/service'
export default function Create() {
    const navigate = useNavigate()
    const { addToast } = useToasts();
    const [serviceCategories, setServiceCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [showSubcategories, setShowSubcategories] = useState([])
    const [serviceCategoryId, setServiceCategoryId] = useState('')
    const [userList, setUserList] = useState([])
    const [isBtnLoading, setIsBtnLoading] = useState(false)
    const [inputData, setInputData] = useState({
        reason: '',
        subCategoryId: '',
        email:'',
        mobile:'',
        userName:''
    })

    const showNotification = (text, type) => {
        addToast(text, { appearance: type })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setIsBtnLoading(true)
        // console.log(inputData)
        createRequestAPi(inputData, serviceCategoryId)
            .then(function (response) {
                // console.log(response.data);
                showNotification("successfully created", "success")
                let tmp = {
                    reason: '',
                    subCategoryId: ''
                }
                setInputData(tmp)
                setTimeout(() => {
                    navigate('?view=ServiceWise')
                }, [1000])

            })
            .catch(function (error) {
                if(error.response.status==400){
                showNotification(error.response.data??"Try Again", "error")
                }else{
                    showNotification("Something went wrong", "error")
                    console.log(error);
                }
            }).finally(()=>{
                setIsBtnLoading(false)
            })
    }
    useEffect(() => {
        getAllCategory()
            .then(function (response) {
                // console.log(response.data);
                let tmp = response.data?.data
                // console.log(tmp, 'all')
                if (tmp) {
                    setServiceCategories(tmp)
                }
            })
            .catch(function (error) {
                showNotification("Something went wrong", "error")
                // console.log(error);
            });

        getAllSubCategory()
            .then(function (response) {
                let tmp = response.data?.data
                // console.log(tmp, 'all 1')
                if (tmp) {
                    setSubCategories(tmp)
                }
            })
            .catch(function (error) {
                showNotification("Something went wrong", "error")
                console.log(error);
            });

        // getAllUser()
        //     .then((res) => {
        //         // console.log(res.data)
        //         setUserList(res.data?.data)
        //     })
        //     .catch(function (error) {
        //         showNotification("Something went wrong", "error")
        //         console.log(error);
        //     });

    }, [])


    useEffect(() => {
        // console.log("csjhdvcjhdsvjh")
        // let selectedCategory=serviceCategoryId
        // console.log(subCategories, serviceCategoryId, 'check')
        let allSubcategories = [...subCategories]
        let tmp = allSubcategories.filter((item) => {
            if (item.categoryId._id == serviceCategoryId) {
                return item
            }
        })
        // console.log(tmp, 'get')
        setShowSubcategories([...tmp])

    }, [serviceCategoryId])
    return (
        <div >
            <form className='userRequestForm' onSubmit={handleSubmit}>
                <div class="form-group">
                    <label>Select Service Category<span className="require">*</span></label>
                    <select class="form-control" id="exampleFormControlSelect1" required
                        onChange={(e) => {
                            setServiceCategoryId(e.target.value)
                        }}
                    >
                        {
                            serviceCategories?.length > 0 ? <>
                                <option disabled selected>--Select Service Category--</option>
                                {
                                    serviceCategories.map((item, index) => {
                                        return <>
                                            <option value={item._id}>{item.name} </option>
                                        </>
                                    })
                                }
                            </> :
                                <>
                                    <option disabled>No Service Category</option>
                                </>
                        }
                    </select>
                </div>
                <div class="form-group">
                    <label>Select Service Sub-Category<span className="require">    </span></label>
                    <select class="form-control" id="exampleFormControlSelect1" 
                        onChange={(e) => {
                            // console.log(e.target.value)
                            let tmp = { ...inputData }
                            tmp.subCategoryId = e.target.value
                            setInputData({ ...tmp })
                        }}
                    >
                        {
                            showSubcategories?.length > 0 ? <>
                                <option disabled selected>--Select Service  Sub-Category--</option>

                                {
                                    showSubcategories.map((item, index) => {
                                        return <>
                                            <option value={item._id}>{item.name} </option>
                                        </>
                                    })
                                }
                            </> :
                                <>
                                    <option disabled>No Service Sub-Category</option>
                                </>
                        }
                    </select>
                </div>
                <label>Customer Name<span className="require">*</span></label>
                <input type="text" className="form-control" value={inputData.userName} onChange={(e) => {
                    // console.log(e.target.value)
                    let tmp = { ...inputData }
                    tmp.userName = e.target.value
                    setInputData({ ...tmp })
                }} />
                <label>Customer Email<span className="require">*</span></label>
                <input type="email" className="form-control" value={inputData.email}
                    onChange={(e) => {
                        // console.log(e.target.value)
                        let tmp = { ...inputData }
                        tmp.email = e.target.value
                        setInputData({ ...tmp })
                    }} />
                <label>Customer Mobile<span className="require">*</span></label>
                <input type="number" className="form-control" value={inputData.mobile}
                    onChange={(e) => {
                        // console.log(e.target.value)
                        let tmp = { ...inputData }
                        tmp.mobile = e.target.value
                        setInputData({ ...tmp })
                    }} />

                <div class="form-group">
                <label>Reason<span className="require">*</span></label>
                    
                    <textarea class="form-control" rows="2" id="message" name="message" placeholder=""
                        onChange={(e) => {
                            let tmp = { ...inputData }
                            tmp.reason = e.target.value
                            setInputData({ ...tmp })
                        }}
                    />
                </div>
                <button className='btn btn-primary' disabled={isBtnLoading}>Submit</button>
            </form>
        </div>
    )
}
